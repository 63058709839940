export const API_URL = "https://sproducts.angel2h.com/api"

export const getNumberFormatted = (number) => {
  number = Number(number).toFixed(2)
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const fullTrim = (str_value) => {
  return `${str_value}`
    .replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, "")
    .replace(/\s+/g, " ")
}
