import React, { Component } from "react";

import NewNavBar from "../../layout/NewNavbar";
import uuid from "uuid";

import {
  configMaterialComponents,
  removeMaterialComponents
} from "../../../utils/MaterialFunctions";

import { connect } from "react-redux";
import { getClients, searchClient } from "../../../actions/clientActions";

import ClientCard from "../../common/ClientCard";
import SearchClientModal from "../../layout/modals/SearchClientModal";

import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";

class Clients extends Component {
  state = {
    field: "",
    searching: false
  };

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getClients();
  }

  onSearch = e => {
    e.preventDefault();
    this.setState({
      searching: true
    });
    this.props.searchClient({ field: this.state.field });
  };

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  getAll = () => {
    this.props.getClients();
  };

  render() {
    const { clients, loading } = this.props.clients;
    const { field } = this.state;
    let clientsContent;

    if (loading) {
      clientsContent = <Spinner fullWidth />;
    } else if (clients.length > 0) {
      clientsContent = clients.map(client => (
        <div className="col s12 m6 l6" key={uuid()}>
          <ClientCard client={client} key={uuid()} />
        </div>
      ));
    } else {
      clientsContent = <EmptyIcon message="No hay clientes" />;
    }

    return (
      <React.Fragment>
        <NewNavBar active_nav="CLIENTES">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Clientes
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            <ul className="right">
              <li>
                <a
                  href="#modal_buscar_cliente"
                  className="tooltipped modal-trigger"
                  data-position="left"
                  data-tooltip="Buscar"
                >
                  <i className="material-icons cursor-pointer">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavBar>
        <main>
          <SearchClientModal
            onsearch={this.onSearch}
            onchange={this.onChangeTextInput}
            onGetAll={this.getAll}
            values={{ field }}
          />
          <div className="row">{clientsContent}</div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.client
});

export default connect(
  mapStateToProps,
  { getClients, searchClient }
)(Clients);
