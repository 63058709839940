import React, { Component } from "react";
import { Link } from "react-router-dom";
import LogoRimeim from "../../../public/img/logo_rimeim.png";
import BannerPortada from "../../../public/img/banner_home.jpg";

import {
  configMaterialComponents,
  removeMaterialComponents
} from "../../../utils/MaterialFunctions";

class Home extends Component {
  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
  }

  render() {
    return (
      <React.Fragment>
        <header className="normal-site">
          <nav className="light-blue darken-2">
            <div className="container">
              <div className="nav-wrapper">
                <a href="https://sproducts.angel2h.com" className="brand-logo">
                  SProducts
                </a>
                <a
                  href="#!"
                  className="sidenav-trigger"
                  data-target="nav_sidenav"
                >
                  <i className="material-icons">menu</i>
                </a>
                <ul className="right hide-on-med-and-down">
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <ul className="sidenav" id="nav_sidenav">
            <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
        </header>
        <div className="row normal-site">
          {/* <div className="showcase" /> */}
          <div
            style={{
              background: `url('${BannerPortada}') no-repeat center center / cover`,
              width: "100%",
              height: "400px"
            }}
          />
        </div>

        <main className="grey lighten-5 normal-site">
          <div className="container">
            <div className="row">
              <div className="col s12">
                <h4>Nuestras funciones</h4>
                <div className="card">
                  <div className="card-content">
                    <p>
                      Administracion de inventarios, locales, empleados,
                      clientes, proveedores y reportes
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col s12">
                <h4>Nuestros clientes</h4>
              </div>
              <div className="col s12 m4">
                <a
                  href="https://rimeim.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="card hoverable bordered"
                    style={{ padding: "10px" }}
                  >
                    <div
                      style={{
                        height: "150px",
                        background: `url('${LogoRimeim}') no-repeat center center / cover`
                      }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer className="grey darken-4 white-text normal-site">
          <div className="container">
            <div className="row mb-0">
              <div className="col s12">
                <h5>Contacto</h5>
                <h6>contacto@angel2h.com</h6>
              </div>
            </div>
            <div className="row mb-0">
              <p className="col s12">
                <span>SProducts &copy; {new Date().getFullYear()}</span>

                <a
                  href="https://angel2h.com"
                  className="right"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  angel2h.com
                </a>
              </p>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Home;
