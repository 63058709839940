import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import uuid from "uuid";
import isEmpty from "../../actions/isEmpty";
import EmptyIcon from "./EmptyIcon";

import { getCurrentDateToInput } from "../../utils/dateFormat";
import SaleCard from "../common/SellCard";
import TextInputField from "../common/TextInputField";
import CheckInputField from "../common/CheckInputField";

const getProductStatus = (status, pedidos = null) => {
  let productStatus = null;
  if (isEmpty(status)) {
    return productStatus;
  }
  if (isEmpty(pedidos)) {
    pedidos = [];
  }
  switch (status) {
    case "ST_INACTIVE":
      productStatus = (
        <span className="d-block">
          Estatus: Inactivo
          <div
            className="circle-element red right bordered"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
      );
      break;

    case "ST_ORDERED":
      productStatus = (
        <React.Fragment>
          <span className="d-block">
            Estatus: Pendiente de entrega
            <div
              className="circle-element amber right bordered"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
          <span className="d-block bordered p-1">
            {pedidos.map(pedido => getContentPedido(pedido))}
          </span>
        </React.Fragment>
      );
      break;
    default:
      break;
  }

  return productStatus;
};

const getContentPedido = pedido => {
  const { proveedor, local_solicitado, cantidad } = pedido;
  return (
    <span className="d-block border-bottom" key={uuid()}>
      {`${cantidad} de `}
      {proveedor && ` Proveedor: ${proveedor.nombre}`}
      {local_solicitado && ` Local: ${local_solicitado.nombre}`}
    </span>
  );
};

class ShowNotifications extends Component {
  state = {
    fecha_inicio: "",
    fecha_final: "",
    aplicar_fechas: false
  };

  componentDidMount() {
    const fecha_actual = getCurrentDateToInput();
    this.setState({
      fecha_inicio: fecha_actual,
      fecha_final: fecha_actual
    });
    this.applyProductosRevisados();
  }

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  onChangeCheckField = e => {
    const current_value = this.state[e.target.name];
    this.setState({ [e.target.name]: !current_value });
  };

  applyProductosRevisados = () => {
    const cache_notificaciones = this.getCacheNotificaciones();
    if (!isEmpty(cache_notificaciones)) {
      const { notificaciones } = this.props.user.user;

      if (!isEmpty(notificaciones)) {
        const { productos } = notificaciones;
        cache_notificaciones.productos.forEach(prod => {
          const indexProducto = productos.findIndex(
            p => (p.id_producto ? p.id_producto : p.id) === prod.id
          );
          if (indexProducto >= 0) {
            productos[indexProducto].revisado = prod.revisado;
          }
        });
      }
    }
  };

  getCacheNotificaciones = () => {
    return JSON.parse(localStorage.getItem("rimeim_cache_notificaciones"));
  };

  onChangeCheckProductoRevisado = producto => {
    let cache_notificaciones = this.getCacheNotificaciones();
    if (isEmpty(cache_notificaciones)) {
      cache_notificaciones = { productos: [] };
    }
    const id_producto = isEmpty(producto.id_producto)
      ? producto.id
      : producto.id_producto;
    const indexProducto = cache_notificaciones.productos.findIndex(
      p => p.id === id_producto
    );
    if (indexProducto >= 0) {
      cache_notificaciones.productos[
        indexProducto
      ].revisado = !cache_notificaciones.productos[indexProducto].revisado;
      this.setCheckProductoRevisado(
        id_producto,
        cache_notificaciones.productos[indexProducto].revisado
      );
    } else {
      cache_notificaciones.productos.push({
        id: id_producto,
        revisado: true
      });
      this.setCheckProductoRevisado(id_producto, true);
    }
    localStorage.setItem(
      "rimeim_cache_notificaciones",
      JSON.stringify(cache_notificaciones)
    );
  };

  getProductoRevisado = id => {
    let revisado = false;
    let cache_notificaciones = this.getCacheNotificaciones();
    if (!isEmpty(cache_notificaciones)) {
      const indexProducto = cache_notificaciones.productos.findIndex(
        p => p.id === id
      );
      if (indexProducto >= 0) {
        revisado = cache_notificaciones.productos[indexProducto].revisado;
      }
    }

    return revisado;
  };

  setCheckProductoRevisado = (id, revisado) => {
    const checkField = document.getElementById(`checkprod_${id}`);
    if (checkField) {
      checkField.checked = revisado;
    }
  };

  getProductCard = (producto, admin = false, currentLocalId = null) => {
    const {
      id,
      id_producto,
      imagen,
      nombre,
      marca,
      tipo_vehiculo,
      existencia,
      cantidad_minima,
      codigo_barra,
      status,
      pedidos,
      congelado,
      ultimas_ventas
    } = producto;
    let card_id_producto = id;
    if (currentLocalId && parseInt(currentLocalId) !== 0) {
      card_id_producto = id_producto;
    }
    return (
      <div className="card hoverable" key={uuid()}>
        {imagen && imagen.url && (
          <div className="card-image border-bottom card-product">
            <img src={imagen.url} alt="" />
          </div>
        )}

        <div className="card-content">
          <Link
            to={`${
              admin && currentLocalId === 0 ? "/admin" : ""
            }/productos/${card_id_producto}`}
          >
            <h6 className="d-block bold">{nombre}</h6>
          </Link>
          <span className="d-block">Codigo: {codigo_barra}</span>
          {marca && <span className="d-block">Marca: {marca.nombre}</span>}
          {tipo_vehiculo && (
            <span className="d-block">
              Tipo de vehiculo: {tipo_vehiculo.nombre}
            </span>
          )}
          <span className="d-block">En Inventario: {existencia}</span>
          <span className="d-block">Cantidad minima: {cantidad_minima}</span>
          {ultimas_ventas && (
            <span className="d-block">
              Ventas en los ultimos 30 dias: {ultimas_ventas}
            </span>
          )}
          {getProductStatus(status, pedidos)}
          {congelado === true && (
            <span className="d-block">
              Producto congelado
              <div
                className="circle-element blue bordered right"
                style={{ width: "20px", height: "20px" }}
              />
            </span>
          )}
          <p>
            <label>
              <input
                id={`checkprod_${card_id_producto}`}
                type="checkbox"
                className="filled-in"
                defaultChecked={
                  this.getProductoRevisado(card_id_producto) ? "checked" : ""
                }
                onChange={this.onChangeCheckProductoRevisado.bind(
                  this,
                  producto
                )}
              />
              <span>Revisado</span>
            </label>
          </p>
        </div>
      </div>
    );
  };

  getOrderCard = order => {
    const { id, codigo, count_productos, fecha_creado, local } = order;
    return (
      <div className="card hoverable" key={uuid()}>
        <Link to={`/pedidos/${id}`}>
          <div className="card-content">
            <span className="d-block">Pedido #{id}</span>
            {codigo && <span className="d-block">Codigo: {codigo}</span>}
            <span className="d-block">
              Pedido desde: {local.codigo} - {local.nombre}
            </span>
            <span className="d-block">
              Productos solicitados: {count_productos}
            </span>
            <span className="d-block">Fecha de creacion: {fecha_creado}</span>
          </div>
        </Link>
      </div>
    );
  };

  getPageContent = () => {
    const { notificaciones } = this.props.user.user;
    if (!isEmpty(notificaciones)) {
      return (
        <div className="col s12">
          {this.getSalesCreditNotifications(notificaciones.creditos)}
          {this.getOrdersNotifications(notificaciones.pedidos)}
          {this.getProductsNotifications(notificaciones.productos)}
        </div>
      );
    } else {
      return <EmptyIcon message="No hay notificaciones" />;
    }
  };

  getProductsNotifications = productos => {
    if (!productos) {
      return null;
    }
    const { fecha_inicio, fecha_final, aplicar_fechas } = this.state;
    const {
      user: { admin },
      currentLocal
    } = this.props.user;
    let products_to_map = [];
    if (aplicar_fechas) {
      products_to_map = productos.filter(
        prod =>
          prod.sin_inventario_desde >= fecha_inicio &&
          prod.sin_inventario_desde <= fecha_final
      );
    } else {
      products_to_map = productos;
    }
    products_to_map = products_to_map.sort(function(a, _) {
      return a.congelado || a.revisado === true ? 1 : -1;
    });
    let productsContent = products_to_map.map(prod =>
      this.getProductCard(prod, admin, currentLocal.id)
    );
    return (
      <div className="card">
        <div className="card-content">
          <h5>Productos con poco inventario {products_to_map.length}</h5>
          <div className="row">
            <CheckInputField
              id="aplicar_fechas"
              label="Ordernar productos"
              checked={aplicar_fechas}
              onchange={this.onChangeCheckField}
            />
          </div>
          <div className="row">
            <TextInputField
              id="fecha_inicio"
              label="Desde"
              type="date"
              input_size="s12 m6"
              value={fecha_inicio}
              active_label={true}
              onchange={this.onChangeTextInput}
            />

            <TextInputField
              id="fecha_final"
              label="Hasta"
              type="date"
              input_size="s12 m6"
              value={fecha_final}
              active_label={true}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <div className="col s12">
              <button
                className="btn"
                onClick={() => {
                  this.applyProductosRevisados();
                  this.forceUpdate();
                }}
              >
                Refrescar productos
              </button>
            </div>
          </div>
          {productsContent}
        </div>
      </div>
    );
  };

  getOrdersNotifications = pedidos => {
    if (!pedidos) {
      return null;
    }
    let ordersContent = pedidos.map(p => this.getOrderCard(p));
    return (
      <div className="card">
        <div className="card-content">
          <h5>Pedidos pendientes {pedidos.length}</h5>
          {ordersContent}
        </div>
      </div>
    );
  };

  getSalesCreditNotifications = sales => {
    if (!sales) return null;
    return (
      <div className="card">
        <div className="card-content">
          <h5>Creditos pendientes</h5>
          {sales.map(sale => (
            <SaleCard
              key={uuid()}
              sell={sale}
              es_cotizacion={false}
              is_admin={false}
            />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const pageContent = this.getPageContent();
    return (
      <main>
        <div className="row">
          <div className="col s12">{pageContent}</div>
        </div>
      </main>
    );
  }
}

ShowNotifications.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ShowNotifications);
