import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'uuid';

import OrderCard from '../../../common/OrderCard';
import Spinner from '../../../common/Spinner';
import NewNavbar from '../../../layout/NewNavbarAdmin';

import {
  configMaterialComponents,
  removeMaterialComponents
} from '../../../../utils/MaterialFunctions';

import { getOrders } from '../../../../actions/orderActions';
import EmptyIcon from '../../../common/EmptyIcon';
import SearchOrderModal from '../../../layout/modals/SearchOrder';

class Orders extends Component {
  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    if (this.props.orders.orders.length === 0) {
      this.props.getOrders();
    }
  }
  render() {
    const {
      orders: { orders, loading },
      user: { currentLocal }
    } = this.props;

    let ordersContent;

    if (loading) {
      ordersContent = <Spinner fullWidth />;
    } else if (orders.length > 0) {
      ordersContent = (
        <div className="row">
          <div className="col s12">
            {orders.map(order => (
              <OrderCard
                order={order}
                key={uuid()}
                currentLocal={currentLocal}
                is_admin={true}
              />
            ))}
          </div>
        </div>
      );
    } else {
      ordersContent = <EmptyIcon message="No hay pedidos" />;
    }

    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Pedidos
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a
                  href="#modal_buscar_pedido"
                  className="tooltipped modal-trigger"
                  data-position="left"
                  data-tooltip="Buscar"
                >
                  <i className="material-icons cursor-pointer">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">{ordersContent}</div>
        </main>

        <SearchOrderModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order,
  user: state.user
});

export default connect(
  mapStateToProps,
  { getOrders }
)(withRouter(Orders));
