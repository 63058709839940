// User
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USERS = "GET_USERS";
export const USER_LOADING = "USER_LOADING";
export const USER_END_LOADING = "USER_END_LOADING";
export const SET_LOCALS = "SET_LOCALS";
export const SET_CURRENT_LOCAL = "SET_CURRENT_LOCAL";

// Locals
export const LOCAL_LOADING = "LOCAL_LOADING";
export const GET_LOCALS = "GET_LOCALS";
export const GET_LOCAL = "GET_LOCAL";

// Employes
export const EMPLOYE_LOADING = "EMPLOYE_LOADING";
export const GET_EMPLOYE = "GET_EMPLOYE";
export const GET_EMPLOYES = "GET_EMPLOYES";

// Products
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const PRODUCT_END_LOADING = "PRODUCT_END_LOADING";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const CLEAR_PRODUCTS_STATE = "CLEAR_PRODUCTS_STATE";
// Brands
export const BRAND_LOADING = "BRAND_LOADING";
export const BRAND_END_LOADING = "BRAND_END_LOADING";
export const GET_BRAND = "GET_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const CLEAR_BRANDS_STATE = "CLEAR_BRANDS_STATE";

// Vehicle
export const VEHICLE_LOADING = "VEHICLE_LOADING";
export const VEHICLE_END_LOADING = "VEHICLE_END_LOADING";
export const GET_VEHICLE = "GET_VEHICLE";
export const GET_VEHICLES = "GET_VEHICLES";
export const CLEAR_VEHICLES_STATE = "CLEAR_VEHICLES_STATE";

// Clients
export const CLIENT_LOADING = "CLIENT_LOADING";
export const CLIENT_LOADING_END = "CLIENT_LOADING_END";
export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const CLEAR_CLIENTS_STATE = "CLEAR_CLIENTS_STATE";

// Providers
export const PROVIDER_LOADING = "PROVIDER_LOADING";
export const PROVIDER_LOADING_END = "PROVIDER_LOADING_END";
export const GET_PROVIDER = "GET_PROVIDER";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const CLEAR_PROVIDERS_STATE = "CLEAR_PROVIDERS_STATE";

// Sells
export const SELL_LOADING = "SELL_LOADING";
export const SELL_END_LOADING = "SELL_END_LOADING";
export const GET_SELL = "GET_SELL";
export const GET_SELLS = "GET_SELLS";
export const SELL_SEND_DATA = "SELL_SEND_DATA";
export const SELL_SUCCESS = "SELL_SUCCESS";
export const SELL_FAILED = "SELL_FAILED";
export const GET_SELL_REPORT = "GET_SELL_REPORT";
export const CLEAR_SELL_STATE = "CLEAR_SELL_STATE";

//Orders
export const ORDER_LOADING = "ORDER_LOADING";
export const ORDER_LOADING_END = "ORDER_LOADING_END";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const CLEAR_ORDERS_STATE = "CLEAR_ORDERS_STATE";
export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE";
export const CLEAR_ORDER_REDUCER = "CLEAR_ORDER_REDUCER";

// Devolutions
export const DEVOLUTION_LOADING = "DEVOLUTION_LOADING";
export const DEVOLUTION_END_LOADING = "DEVOLUTION_END_LOADING";
export const GET_DEVOLUTIONS = "GET_DEVOLUTIONS";
export const GET_DEVOLUTION = "GET_DEVOLUTION";
export const CLEAR_DEVOLUTIONS_STATE = "CLEAR_DEVOLUTIONS_STATE";

export const GET_GLOBAL_VARIABLES = "GET_GLOBAL_VARIABLES";
export const GLOBAL_VARIABLES_LOADING = "GLOBAL_VARIABLES_LOADING";
export const GLOBAL_VARIABLES_END_LOADING = "GLOBAL_VARIABLES_END_LOADING";

// Error
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
