import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Link } from 'react-router-dom';

import Spinner from './Spinner';
import EmptyIcon from './EmptyIcon';
import { getNumberFormatted } from '../../utils/stringUtils';
import isEmpty from '../../actions/isEmpty';
import { getDaysDiffDate } from '../../utils/dateFormat';

const getStatus = (status, id_venta) => {
  if (status !== 'ST_VENDIDA') return null;
  id_venta = 100;
  let description = <span className="bold">Estatus: Vendida</span>;

  if (id_venta) {
    description = (
      <Link
        to={`/ventas/${id_venta}`}
        style={{
          color: '#039be5'
        }}
      >
        Estatus: Vendida
      </Link>
    );
  }

  return (
    <span className="d-block">
      {description}
      <div
        className="circle-element green right bordered"
        style={{ width: '20px', height: '20px' }}
      />
    </span>
  );
};

const ShowSale = props => {
  const {
    loading,
    sale,
    es_cotizacion,
    onDelete,
    addCreditDays,
    finishCredit
  } = props;
  let saleContent;

  if (loading) {
    saleContent = <Spinner fullWidth />;
  } else if (!isEmpty(sale)) {
    const {
      id,
      local,
      cliente,
      usuario_creador,
      codigo,
      con_factura,
      sub_total,
      impuesto,
      total,
      id_venta,
      status,
      metodo_pago,
      dias_credito,
      credito_pagado,
      fecha_credito_pagado,
      fecha_creado,
      productos
    } = sale;
    saleContent = (
      <div className="card">
        <div className="card-content venta-factura">
          {local && (
            <span className="d-block bold">
              Local:
              {`${local.id} - ${local.nombre}`}
            </span>
          )}

          <span className="d-block">
            <span className="bold">ID: </span>
            <span className="ml-1">{id}</span>
          </span>

          {codigo && (
            <span className="d-block">
              <span className="bold">Codigo: </span>
              <span className="ml-1">{codigo}</span>
            </span>
          )}

          {cliente && (
            <span className="d-block">
              <span className="bold">Cliente: </span>
              <span className="ml-1">{cliente.nombre}</span>
            </span>
          )}

          {cliente && cliente.rtn && (
            <span className="d-block">
              <span className="bold">RTN: </span>
              <span className="ml-1">{cliente.rtn}</span>
            </span>
          )}

          <span className="d-block">
            <span className="bold">Vendedor: </span>
            <span className="ml-1">
              {usuario_creador.id} - {usuario_creador.nombre}
            </span>
          </span>

          {es_cotizacion && getStatus(status, id_venta)}

          {fecha_credito_pagado && (
            <span className="d-block">
              <span className="bold">El credito fue pagado en: </span>
              <span className="ml-1">{fecha_credito_pagado}</span>
            </span>
          )}
          <span className="d-block">
            <span className="bold">Fecha: </span>
            <span className="ml-1">{fecha_creado}</span>
          </span>
          <div className="horizontal-scroll-container no-padding">
            <table className="table-bordered striped table-cotizacion">
              <thead>
                <tr>
                  <th className="mxw-cotizacion">Codigo</th>
                  <th>Ubicacion</th>
                  <th>Descripcion</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {productos.map(prod => (
                  <tr key={uuid()}>
                    <td className="mxw-cotizacion">{prod.codigo_barra}</td>
                    <td>{prod.ubicacion}</td>
                    <td>{prod.nombre}</td>
                    <td>{prod.cantidad}</td>
                    <td>{getNumberFormatted(prod.precio)}</td>
                    <td>{getNumberFormatted(prod.total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <span className="d-block">
            <span className="bold">Sub Total: </span>
            <span className="ml-1">Lps {getNumberFormatted(sub_total)}</span>
          </span>
          <span className="d-block">
            <span className="bold">ISV: </span>
            <span className="ml-1">Lps {getNumberFormatted(impuesto)}</span>
          </span>
          <span className="d-block">
            <span className="bold">Total:</span>
            <span className="ml-1">Lps {getNumberFormatted(total)}</span>
          </span>
          {!es_cotizacion && (
            <span className="d-block">
              <span className="bold">Metodo de pago: </span>
              <span className="ml-1">
                {metodo_pago} - {con_factura ? 'C/F' : 'S/F'}
              </span>
            </span>
          )}
          {!es_cotizacion && metodo_pago === 'credito' && !credito_pagado && (
            <span className="d-block">
              <span className="bold">
                Dias restantes:{' '}
                {dias_credito -
                  getDaysDiffDate(new Date(fecha_creado), new Date())}
              </span>
            </span>
          )}
        </div>

        <div className="card-footer p-1">
          {es_cotizacion ? (
            <React.Fragment>
              <button
                className="btn red darken-3"
                onClick={() => {
                  if (onDelete) {
                    onDelete();
                  }
                }}
              >
                Eliminar cotizacion
              </button>

              <Link
                to={`/nueva_venta/${sale.id}`}
                className="btn ml-1 text-white"
              >
                Facturar
              </Link>
            </React.Fragment>
          ) : (
            <div>
              <Link
                to={`/nueva_devolucion/${sale.id}`}
                className="btn red darken-3 text-white mt-1 ml-1"
              >
                Generar devolucion
              </Link>

              {!es_cotizacion && metodo_pago === 'credito' && !credito_pagado && (
                <React.Fragment>
                  <button
                    className="btn ml-1 mt-1"
                    onClick={() => {
                      if (addCreditDays) {
                        addCreditDays();
                      }
                    }}
                  >
                    Agregar dias al credito
                  </button>

                  <button
                    className="btn ml-1 mt-1 green darken-1"
                    onClick={() => {
                      if (finishCredit) {
                        finishCredit();
                      }
                    }}
                  >
                    Credito pagado
                  </button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    saleContent = <EmptyIcon message="No hay informacion para mostrar" />;
  }
  return saleContent;
};

ShowSale.propTypes = {
  sale: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  es_cotizacion: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  addCreditDays: PropTypes.func,
  finishCredit: PropTypes.func
};

ShowSale.defaultProps = {
  es_cotizacion: false
};

export default ShowSale;
