import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import NewNavbar from '../../layout/NewNavbar';

// Functions
import {
  configMaterialComponents,
  removeMaterialComponents,
  notificationError,
  getModalInstanceById,
  configSelectInputFields
} from '../../../utils/MaterialFunctions';
import { clearSellState, addNewSell } from '../../../actions/sellActions';
import { searchProduct } from '../../../actions/productActions';
import isEmpty from '../../../actions/isEmpty';

import { getGlobalVariables } from '../../../actions/globalActons';
import { getNumberFormatted } from '../../../utils/stringUtils';

import SearchClientModal from '../../layout/modals/SearchAndSelectClient';
import SellConfigurationModal from '../../layout/modals/SellConfiguration';
import SellCheckoutModal from '../../layout/modals/SellCheckout';

import ConfirmatioModal from '../../layout/modals/ConfirmationModal';

import Spinner from '../../common/Spinner';
import InputField from '../../common/TextInputField';
import ProductCard from '../../common/ProductCard';

class NewFastSale extends Component {
  state = {
    search_input: '',
    typing: false,
    typingTimeout: 0,
    searching: false,
    ///
    guardar_como_cotizacion: false,
    aplicar_impuesto: true,
    component_message: '',
    currentClient: {},
    products: []
  };

  input_cantidad = 'INPUT_CANTIDAD';
  input_precio = 'INPUT_PRECIO';
  is_sending_data = false;

  componentWillMount() {
    removeMaterialComponents();
  }

  componentWillUnmount() {
    this.props.clearSellState();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getGlobalVariables();
  }

  componentDidUpdate() {
    configSelectInputFields();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.sell &&
      this.is_sending_data &&
      !nextProps.sell.loading &&
      nextProps.sell.sell_success &&
      nextProps.errors &&
      isEmpty(nextProps.errors)
    ) {
      let new_modal_message = '';
      if (nextProps.sell.sell_success) {
        new_modal_message = 'La venta se ha guardado exitosamente';
      } else {
        new_modal_message =
          'Ocurrio un error en el proceso, por favor notificar al desarrollador';
      }
      this.is_sending_data = false;
      this.setState({
        component_message: new_modal_message
      });
      getModalInstanceById('modal_sell_checkout').close();
      getModalInstanceById('modal_confirmar_evento').open();
    }
  }

  onChangeTextInput = e => {
    if (this.state.typingTimeout) {
      this.setState({ searching: true });
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      search_input: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        if (!isEmpty(this.state.search_input)) {
          this.props.searchProduct({
            field: this.state.search_input
          });
        } else {
          this.setState({ searching: false });
        }
      }, 1000)
    });
  };

  onChangeImpuesto = () => {
    this.setState({
      aplicar_impuesto: !this.state.aplicar_impuesto
    });
  };

  onAddProductClick = product => {
    const { products } = this.state;
    product = JSON.parse(JSON.stringify(product));
    products.push({
      local_id: uuid(),
      indice: products.length + 1,
      id_producto: product.id,
      codigo_barra: product.codigo_barra,
      cantidad: 1,
      nombre: product.nombre,
      precio: product.precio
    });
    this.setState({ products });
  };

  onDeleteProductClick = product => {
    const { products } = this.state;
    this.setState({
      products: products.filter(p => p.local_id !== product.local_id)
    });
  };

  onSelectClient = client => {
    this.setState({
      currentClient: client
    });
  };

  onInputKeyPress = (row_id, row_type, e) => {
    if (e.keyCode === 13) {
      //Entar
      let newValue = document
        .getElementById(`${row_type}${row_id}`)
        .value.trim();
      newValue =
        row_type === this.input_cantidad
          ? parseInt(newValue)
          : parseFloat(newValue);

      if (newValue >= 0) {
        this.setValueToProductRow(row_type, row_id, newValue);
      } else {
        this.goBackToInputValue(row_type, row_id);
      }
    } else if (e.keyCode === 8 && !e.ctrlKey) {
      // Simple DELETE
      return;
    } else if (e.keyCode === 27) {
      // Escape
      this.goBackToInputValue(row_type, row_id);
    } else if (e.keyCode === 17) {
      // Control
      return;
    }
  };

  goBackToInputValue = (row_type, local_id) => {
    document.getElementById(
      `${row_type}${local_id}`
    ).value = this.getBackInputValue(row_type, local_id);
  };

  getBackInputValue = (row_type, local_id) => {
    let backValue = '';
    const product = this.state.products.find(p => p.local_id === local_id);
    if (!product) return backValue;

    switch (row_type) {
      case this.input_cantidad:
        backValue = product.cantidad;
        break;
      case this.input_precio:
        backValue = product.precio;
        break;
      default:
        break;
    }
    return backValue;
  };

  setValueToProductRow = (row_type, local_id, value) => {
    const { products } = this.state;
    const productIndex = products.findIndex(p => p.local_id === local_id);
    if (productIndex < 0) return;

    if (row_type === this.input_cantidad) {
      products[productIndex].cantidad = value;
    } else if (row_type === this.input_precio) {
      products[productIndex].precio = value;
    }
    this.setState({
      products
    });
  };

  onHideModal = () => {
    this.setState({
      guardar_como_cotizacion: false
    });
  };

  onAcceptConfirm = () => {
    if (
      this.state.component_message === 'La venta se ha guardado exitosamente'
    ) {
      window.location = '/venta_rapida';
    }
  };

  onSaveAsQuotation = () => {
    this.setState({
      guardar_como_cotizacion: true
    });
    getModalInstanceById('modal_sell_checkout').open();
  };

  openModalCheckOut = () => {
    if (this.state.products.length > 0) {
      getModalInstanceById('modal_sell_checkout').open();
    } else {
      notificationError('No hay productos para facturar');
    }
  };

  onCheckOutSell = saleData => {
    this.is_sending_data = true;
    saleData.productos = this.state.products;
    saleData.es_cotizacion = this.state.guardar_como_cotizacion;
    this.props.addNewSell(saleData);
  };

  getProductsFromSearch = () => {
    const { products, loading } = this.props.product;
    return (
      <React.Fragment>
        {loading && <Spinner fullWidth />}
        {products.map(product => (
          <ProductCard
            product={product}
            key={uuid()}
            noLink={true}
            addButton={true}
            onAddClick={this.onAddProductClick}
          />
        ))}
      </React.Fragment>
    );
  };

  getSelectedProducts = () => {
    const { products } = this.state;
    return products.map(product => (
      <tr key={uuid()}>
        <td style={{ padding: '0 2px' }} className="center">
          <button
            className="btn red"
            onClick={this.onDeleteProductClick.bind(this, product)}
          >
            <i className="material-icons">delete</i>
          </button>
        </td>
        <td>{product.codigo_barra}</td>
        <td>{product.nombre}</td>
        <td className="td-with-input">
          <input
            type="text"
            id={`${this.input_cantidad}${product.local_id}`}
            className="special-input browser-default"
            onKeyUp={this.onInputKeyPress.bind(
              this,
              product.local_id,
              this.input_cantidad
            )}
            defaultValue={product.cantidad}
          />
        </td>
        <td className="td-with-input">
          <input
            type="text"
            id={`${this.input_precio}${product.local_id}`}
            className="special-input browser-default"
            onKeyUp={this.onInputKeyPress.bind(
              this,
              product.local_id,
              this.input_precio
            )}
            defaultValue={product.precio}
          />
        </td>
      </tr>
    ));
  };

  getSaleTotalValues = sumValues => {
    return (
      <div className="fast-sale-total">
        <div className="fast-sale-value">
          <span className="title">Sub Total</span>
          <span className="total">
            Lps {getNumberFormatted(sumValues.subtotal)}
          </span>
        </div>
        <div className="fast-sale-value">
          <span className="title">Impuesto</span>
          <span className="total">
            Lps {getNumberFormatted(sumValues.impuesto)}
          </span>
        </div>
        <div className="fast-sale-value">
          <span className="title">Total</span>
          <span className="total">
            Lps {getNumberFormatted(sumValues.total)}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      currentClient,
      component_message,
      guardar_como_cotizacion,
      products,
      aplicar_impuesto
    } = this.state;

    let subtotal = 0;
    products.forEach(
      p => (subtotal += parseFloat(p.cantidad) * parseFloat(p.precio))
    );

    let impuesto = 0;

    if (aplicar_impuesto) {
      impuesto =
        subtotal *
        (this.props.global.impuesto ? this.props.global.impuesto : 0.15);
    }

    const sumValues = {
      subtotal,
      impuesto,
      total: subtotal + impuesto
    };
    return (
      <React.Fragment>
        <NewNavbar active_nav="VENTAS">
          <ul id="dropdown_more" className="dropdown-content">
            <li>
              <a href="#modal_sell_configuracion" className="modal-trigger">
                <i className="material-icons">settings</i>
              </a>
            </li>
            <li>
              <a href="#!" onClick={this.onSaveAsQuotation}>
                <i className="material-icons">save</i>
              </a>
            </li>
            <li>
              <a href="#!" onClick={this.openModalCheckOut}>
                <i className="material-icons">check</i>
              </a>
            </li>
          </ul>

          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Nueva venta rapida
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right hide-on-small-only">
              <li>
                <a href="#modal_sell_configuracion" className="modal-trigger">
                  <i className="material-icons">settings</i>
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  className="tooltipped"
                  data-position="bottom"
                  data-tooltip="Guardar como cotizacion"
                  onClick={this.onSaveAsQuotation}
                >
                  <i className="material-icons">save</i>
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  className="tooltipped"
                  data-position="bottom"
                  data-tooltip="Facturar"
                  onClick={this.openModalCheckOut}
                >
                  <i className="material-icons">check</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row" style={{ padding: '10px' }}>
            <div className="card">
              <div className="card-content fast-sale">
                <div className="row">
                  <div className="col s12 m4 l3 fast-sale-input-products">
                    <div className="fast-sale-search">
                      <InputField
                        id="search_input"
                        value={this.state.search_input}
                        onchange={this.onChangeTextInput}
                        placeholder="Buscar producto"
                      />
                    </div>
                    <div className="fast-sale-products">
                      {this.getProductsFromSearch()}
                    </div>
                  </div>
                  <div className="col s12 m8 l9 no-padding fast-sale-content">
                    <div className="fast-sale-table">
                      <table className="table-bordered striped">
                        <thead>
                          <tr>
                            <th style={{ width: '20px !important' }}></th>
                            <th className="center">Codigo</th>
                            <th className="center">Descripcion</th>
                            <th className="center">Cantidad</th>
                            <th className="center">Precio</th>
                          </tr>
                        </thead>
                        <tbody>{this.getSelectedProducts()}</tbody>
                      </table>
                    </div>

                    {this.getSaleTotalValues(sumValues)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <SearchClientModal
          onHide={this.onHideModal}
          currentClient={currentClient}
          onSelectClient={this.onSelectClient}
        />
        <SellConfigurationModal
          id_search_client_modal="modal_seleccionar_cliente"
          currentClient={currentClient}
          onHide={this.onHideModal}
          onChangeImpuesto={this.onChangeImpuesto}
        />
        <SellCheckoutModal
          currentClient={currentClient}
          loading={this.props.sell.loading}
          errors={this.props.errors}
          sumValues={sumValues}
          onHide={this.onHideModal}
          onAccept={this.onCheckOutSell}
          es_cotizacion={guardar_como_cotizacion}
        />

        <ConfirmatioModal
          title="Aviso"
          message={component_message}
          onAccept={this.onAcceptConfirm}
        />
      </React.Fragment>
    );
  }
}

NewFastSale.propTypes = {
  errors: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  addNewSell: PropTypes.func.isRequired,
  getGlobalVariables: PropTypes.func.isRequired,
  clearSellState: PropTypes.func.isRequired,
  searchProduct: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sell: state.sell,
  errors: state.errors,
  global: state.global,
  product: state.product
});

export default connect(mapStateToProps, {
  addNewSell,
  getGlobalVariables,
  clearSellState,
  searchProduct
})(NewFastSale);
