import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getProducts } from "../../actions/productActions";
import { removeUserInBottomPage } from "../../utils/documentUtils";

import MapProducts from "./MapProducts";

class ShowProducts extends Component {
  state = {
    is_getting_results: false
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.product &&
      !nextProps.product.loading &&
      this.state.is_getting_results
    ) {
      this.setState({
        is_getting_results: false
      });
    }
  }

  componentDidMount() {
    if (this.props.product.products.length === 0) {
      this.props.getProducts(this.props.product.products.length);
    }
    //onUserInBottomPage(this.getMoreResults);
  }

  componentWillUnmount() {
    removeUserInBottomPage();
  }

  getMoreResults = () => {
    if (!this.state.is_getting_results && !this.props.searching) {
      this.props.getProducts(this.props.product.products.length);
      this.setState({ is_getting_results: true });
    }
  };

  render() {
    const {
      product: { loading, products },
      admin
    } = this.props;
    return (
      <React.Fragment>
        <MapProducts loading={loading} products={products} admin={admin} />

        <div style={{ width: "100% !important", height: "100px" }} />
      </React.Fragment>
    );
  }
}

ShowProducts.propTypes = {
  getProducts: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired
};

ShowProducts.defaultProps = {
  admin: false,
  searching: false
};

const mapStateToProps = state => ({
  product: state.product,
  user: state.user
});

export default connect(
  mapStateToProps,
  { getProducts }
)(ShowProducts);
