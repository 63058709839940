import React, { Component } from 'react'
import { connect } from "react-redux"

import { configSelectInputFields, getModalInstanceById } from "../../../utils/MaterialFunctions"

import { searchOrder, getOrders } from "../../../actions/orderActions"
import { getLocals, localsToSelectOptions } from "../../../actions/LocalActions"
import { getProviders, providersToSelectOptions } from "../../../actions/providerActions"

import TextInputField from "../../common/TextInputField"
import SelectInputField from "../../common/SelectInputField"
import isEmpty from '../../../actions/isEmpty';

class SearchOrder extends Component {
    state = {
        codigo: '',
        id_proveedor: '0',
        id_local: '0',
        fecha_inicio: '',
        fecha_final: '',
        config_selects: false
      };

      componentDidMount() {
          this.props.getProviders()
          if(this.isAdminArea()) {
              this.props.getLocals()
          }
      }

      componentWillReceiveProps(nextProps) {
          if(!this.state.config_selects) {
              if(nextProps.provider.providers && 
                nextProps.provider.providers.length > 0 ) {
                    this.setState({ config_selects: true })
                }

                if(nextProps.local.locals && 
                    nextProps.local.locals.length > 0 ) {
                        this.setState({ config_selects: true })
                    }
          }
      }

      componentDidUpdate() {
          if(this.state.config_selects) {
            configSelectInputFields()
          }
      }

      isAdminArea = () => {
          const { user: { user, currentLocal } } = this.props
          return user.admin && currentLocal.id === "0"
      }

      onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

      closeModal = () => {
        getModalInstanceById("modal_buscar_pedido").close()
      }

      onGetAll = () => {
          this.props.getOrders()
          this.closeModal()
      }

      onSearchOrder = () => {
        let { codigo, id_proveedor, id_local, fecha_inicio, fecha_final } = this.state
        if(id_proveedor === "0") id_proveedor = null
        if(id_local === "0") id_local = null
        if(isEmpty(fecha_inicio)) fecha_inicio = null 
        if(isEmpty(fecha_final)) fecha_final = null

        const searchData = {
            codigo,
            id_proveedor,
            id_local,
            fecha_inicio,
            fecha_final
        }
        this.props.searchOrder(searchData)
        this.closeModal()
      }

    render() {
        const { codigo, id_proveedor, id_local, fecha_inicio, fecha_final } = this.state
        const providerOptions = providersToSelectOptions(this.props.provider.providers)
        let localOptions = []
        if(this.isAdminArea()) {
            localOptions = localsToSelectOptions(this.props.local.locals)
        }
        return (
            <div className="modal" id="modal_buscar_pedido">
                <div className="modal-content">
                    <h5>Buscar pedido</h5>
                    <div className="row">
                        <TextInputField 
                            id="codigo"
                            label="Codigo"
                            value={codigo}
                            onchange={this.onChangeTextInput} />
                    </div>

                    <div className="row">
                        <SelectInputField id="id_proveedor" 
                            label="Proveedor"
                            onchange={this.onChangeTextInput}
                            value={id_proveedor}
                            options={providerOptions}/>
                    </div>

                    {this.isAdminArea() && (
                        <div className="row">
                        <SelectInputField id="id_local" 
                            label="Local"
                            onchange={this.onChangeTextInput}
                            value={id_local}
                            options={localOptions}/>
                    </div>
                    )}

                    <div className="row">
                        <TextInputField 
                            id="fecha_inicio"
                            input_size="s12 m6"
                            label="Desde"
                            type="date"
                            value={fecha_inicio}
                            onchange={this.onChangeTextInput} />
                        <TextInputField 
                            id="fecha_final"
                            input_size="s12 m6"
                            label="Hasta"
                            type="date"
                            value={fecha_final}
                            onchange={this.onChangeTextInput} />
                    </div>
                </div>

                <div className="modal-footer p-1">
                    <a href="#!" className="btn-flat modal-close left">Cerrar</a>
                    <a href="#!" className="btn right ml-1" onClick={this.onSearchOrder}>Buscar</a>
                    <a href="#!" className="btn left mr-1" onClick={this.onGetAll}>Obtener todo</a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    local: state.local,
    provider: state.provider
})

export default connect(mapStateToProps,  { searchOrder, getOrders, getLocals, getProviders })(SearchOrder)