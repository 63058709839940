import {
  PROVIDER_LOADING,
  PROVIDER_LOADING_END,
  GET_PROVIDER,
  GET_PROVIDERS
} from './types';

import axios from 'axios';

import { clearErrors, handleError } from './errorActions';
import { configUserFromResponse } from './UserActions';
import { API_URL } from '../utils/stringUtils';

export const addProvider = (data, history) => dispatch => {
  dispatch(clearErrors());
  dispatch(providerLoading());
  axios
    .post(`${API_URL}/providers/add`, data)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(providerLoadingEnd());
      history.push(`/proveedores/${response.data.id}`);
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};
export const editProvider = (data, history, id) => dispatch => {
  dispatch(clearErrors());
  axios
    .post(`${API_URL}/providers/update/${id}`, data)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: PROVIDER_LOADING_END
      });
      history.push(`/proveedores/${response.data.id}`);
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};
export const getProvider = id => dispatch => {
  dispatch(providerLoading());
  axios
    .get(`${API_URL}/providers/get_one/${id}`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);

      dispatch({
        type: GET_PROVIDER,
        payload: response.data
      });
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};
export const getProviders = () => dispatch => {
  dispatch(providerLoading());
  dispatch(clearErrors());
  axios
    .get(`${API_URL}/providers/get`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PROVIDERS,
        payload: response.data
      });
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};
export const searchProvider = data => dispatch => {
  dispatch(providerLoading());
  axios
    .post(`${API_URL}/providers/search`, data)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PROVIDERS,
        payload: response.data
      });
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};
export const deleteProvider = (id, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .delete(`${API_URL}/providers/delete/${id}`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PROVIDER,
        payload: {}
      });
      history.push('/proveedores');
    })
    .catch(err => handleError(err, dispatch, PROVIDER_LOADING_END));
};

export const providersToSelectOptions = providers => {
  const options = [];
  providers.forEach(prov => {
    options.push({
      value: prov.id,
      label: `${prov.rtn ? prov.rtn + ' - ' : ''}${prov.nombre}`
    });
  });
  return options;
};

export const providerLoading = () => {
  return {
    type: PROVIDER_LOADING
  };
};
export const providerLoadingEnd = () => {
  return {
    type: PROVIDER_LOADING_END
  };
};
