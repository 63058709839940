import {
  GET_ORDER,
  GET_ORDERS,
  ORDER_LOADING,
  ORDER_LOADING_END,
  CLEAR_ORDER_STATE,
  CLEAR_ORDER_REDUCER
} from "./types";
import axios from "axios";

import { clearErrors, handleError } from "./errorActions";
import { configUserFromResponse } from "./UserActions";
import { API_URL } from "../utils/stringUtils";

export const createOrder = (data, history, new_url = "") => dispatch => {
  dispatch(orderLoading());
  axios
    .post(`${API_URL}/orders/add`, data)
    .then(res => {
      dispatch(clearErrors());
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: ORDER_LOADING_END
      });
      history.push(`${new_url}/pedidos/${response.data.id}`);
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const editOrder = (id, data, anotherAction = null) => async dispatch => {
  dispatch(orderLoading());
  try {
    const serverResponse = await axios.put(
      `${API_URL}/orders/update/${id}`,
      data
    );
    const response = serverResponse.data;
    configUserFromResponse(response, dispatch);
    dispatch({
      type: ORDER_LOADING_END
    });
    if (anotherAction) {
      anotherAction();
    }
  } catch (err) {
    handleError(err, dispatch, ORDER_LOADING_END);
  }
};

export const getOrder = id => dispatch => {
  dispatch(clearErrors());
  dispatch(orderLoading());
  axios
    .get(`${API_URL}/orders/get_one/${id}`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_ORDER,
        payload: response.data
      });
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const markReceived = id => dispatch => {
  dispatch(orderLoading());
  axios
    .put(`${API_URL}/orders/mark_received/${id}`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: ORDER_LOADING_END
      });
      window.location.reload();
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const getOrders = () => dispatch => {
  dispatch(orderLoading());
  axios
    .get(`${API_URL}/orders/get`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_ORDERS,
        payload: response.data
      });
      dispatch(clearErrors());
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const searchOrder = data => dispatch => {
  dispatch(clearErrors());
  dispatch(orderLoading());
  axios
    .post(`${API_URL}/orders/search`, data)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_ORDERS,
        payload: response.data
      });
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const deleteOrder = (id, history, newUrl) => dispatch => {
  dispatch(clearErrors());
  dispatch(orderLoading());
  axios
    .delete(`${API_URL}/orders/delete/${id}`)
    .then(res => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_ORDER,
        payload: {}
      });
      history.push(newUrl);
    })
    .catch(err => handleError(err, dispatch, ORDER_LOADING_END));
};

export const clearOrder = () => dispatch => {
  dispatch({
    type: CLEAR_ORDER_STATE
  });
};

export const clearOderReducer = () => dispatch => {
  dispatch({
    type: CLEAR_ORDER_REDUCER
  });
};

export const orderLoading = () => {
  return {
    type: ORDER_LOADING
  };
};

export const orderEndLoading = () => {
  return {
    type: ORDER_LOADING_END
  };
};
